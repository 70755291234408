.container {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding-top: 10px;
}

.image-card {
  padding: 5px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

@media only screen and (max-width: 600px) {
  .image-card {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}